import React, { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Definite, PropComponent } from '../../helpers/dataHelpers';
import style from './home.testimonial.module.scss';

export type TestimonialProps = Pick<Definite<GatsbyTypes.HomeQuery['contentfulHomeContent']>, 'testimonialImage' | 'testimonial'>;

export const Testimonial: PropComponent<Partial<TestimonialProps>> = ({testimonialImage, testimonial}) => {
    const padding = '48px';

    let [boxDetails, setBoxDetails] = useState({
        height: `0px`,
        width: `0px`,
        top: `0px`,
        left: `0px`
    });

    let calcBoxDetails = () => {
        let content = document.querySelector('#testimonialInner') as HTMLElement;
        if (content) {
            setBoxDetails(getBoxDetails())
        }
    };
    let getBoxDetails = () => {
        let content = document.querySelector('#testimonialInner') as HTMLElement;
        return {
            height: `${content.offsetWidth}px`,
            width: `${content.offsetWidth}px`,
            top: `calc(${content.offsetTop}px - (${content.offsetWidth/2}px - ${content.offsetHeight/2}px))`,
            left: `${content.offsetLeft}px`,
        };
    }
    let calcAnimations = (entries) => {
        for(let entry of entries) {
            let box = document.querySelector('#testimonialBox') as HTMLElement;
            if (!box) return;
            
            if(entry.isIntersecting) {
                box.classList.remove(style.rotateAnimIn);
                box.classList.add(style.rotateAnimOut);
            } else {
                box.classList.remove(style.rotateAnimOut);
                box.classList.add(style.rotateAnimIn);
            }
        }
    }

    useEffect(() => {
        let box = document.querySelector('#testimonialBox') as HTMLElement;
        if (box && boxDetails) {
            box.style.height = boxDetails.height;
            box.style.width = boxDetails.width;
            box.style.top = `calc(${boxDetails.top} - ${padding})`;
            box.style.left = `calc(${boxDetails.left} - ${padding})`;
        }
    }, [boxDetails]);

    useEffect(() => {
        let res = new ResizeObserver(calcBoxDetails);
        let sec = new IntersectionObserver(calcAnimations, {threshold: 1});
        const testimonial = document.querySelector('#testimonial');
        testimonial ? res.observe(testimonial) : null;
        const innterTestimonial = document.querySelector('#testimonialInner');
        innterTestimonial ? sec.observe(innterTestimonial) : null;
        setTimeout(() => setBoxDetails(getBoxDetails()), 50)
    }, []);

    return (<div id="testimonial" className={style.testimonial} style={{'backgroundImage': `url(${testimonialImage?.fixed?.src})`}}>
        <div id="testimonialBox" className={style.testimonialBox}></div>
        <div className={style.testimonialContent}>
            <div id="testimonialInner" className={style.quoteWrapper}>
                <div className={style.testimonialQuotation}>"</div>
                <blockquote>{testimonial?.testimonial}</blockquote>
            </div>
        </div>
    </div>);
}

export default Testimonial;
