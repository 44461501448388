import React, { useState } from 'react';
import style from './home.expertise.module.scss';
import { CircleIcon, URLIcon } from '../icon';
import classNames from '../../helpers/classNames';
import { PropComponent } from '../../helpers/dataHelpers';

export type ExpertiseProps = {
    capabilities: GatsbyTypes.HomeQuery['allContentfulCapability'],
    categories: GatsbyTypes.HomeQuery['allContentfulCapabilityCategory']
};

const Expertise: PropComponent<ExpertiseProps> = ({capabilities, categories}) => {
    let [currentCategory, setCurrentCategory] = useState(categories.edges[0].node.id);

    return (<div className={style.expertise}>
        <div className={style.header}><h2>Our Expertise</h2></div>
        <div className={style.expertiseContent}>
            <div className={style.toggle}>
                <div className={style.toggleContent}>
                    {categories.edges.map(c =>
                        <button onClick={() => setCurrentCategory(c.node.id)} className={classNames(style.toggleButton, c.node.id === currentCategory ? style.toggleButtonActive : '')} key={c.node.id}>{c.node.title}</button>)}
                </div>
            </div>

            <div className={style.description}><p>Centeva is an innovative provider of powerful and dynamic professional and technical services to drive modernization and success for our clients.</p></div>
            <div className={style.capabilities}>
                <div className={style.capabilitiesContent}>
                    {capabilities.edges
                        .filter(c => c?.node?.category && c.node.category.id === currentCategory)
                        .map((c, i) => (<div className={style.capability} key={(c?.node?.name || '') + i}>
                            {(c.node && c.node.icon && c.node.icon.file && c.node.icon.file.url) ? <URLIcon url={c.node.icon.file.url}></URLIcon> : <CircleIcon></CircleIcon>}
                            {/* TODO: update to use category slug when we break out capabilities. */}
                            <a href={`/capabilities/${c.node.slug}`}>{c.node.name}</a>
                        </div>))}
                </div>
            </div>
        </div>
    </div>);
}

export default Expertise;
