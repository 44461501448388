import style from './home.highlight.module.scss';
import HighlightCard from '../highlightCard';
import { Definite, PropComponent } from '../../helpers/dataHelpers';
import React, { useEffect, useState } from 'react';

export type highlightProps = Pick<
Definite<GatsbyTypes.HomeQuery['contentfulHomeContent']>,
| 'highlightDescription'
| 'highlightSubtitle'
| 'highlightImage'
| 'highlightButton'
| 'highlightLink'
| 'highlightTitle'
>;

const Highlight: PropComponent<Partial<highlightProps>> = ({
  highlightDescription,
  highlightSubtitle,
  highlightImage,
  highlightButton,
  highlightLink,
  highlightTitle,
}) => {
  const padding = '48px';

  let [boxDetails, setBoxDetails] = useState({
    height: `0px`,
    width: `0px`,
    top: `0px`,
    left: `0px`,
  });

  let calcBoxDetails = () => {
    let content = document.querySelector('#highlightInner') as HTMLElement;
    if (content) {
      setBoxDetails(getBoxDetails());
    }
  };
  let getBoxDetails = () => {
    let content = document.querySelector('#highlightInner') as HTMLElement;
    return {
      height: `${content.offsetHeight}px`,
      width: `${content.offsetWidth}px`,
      top: `${content.offsetTop}px`,
      left: `${content.offsetLeft}px`,
    };
  };
  let calcAnimations = entries => {
    for (let entry of entries) {
      let box = document.querySelector('#highlightBox');
      if (!box) continue;
      if (entry.isIntersecting) {
        box.classList.remove(style.rotateAnimIn);
        box.classList.add(style.rotateAnimOut);
      } else {
        box.classList.remove(style.rotateAnimOut);
        box.classList.add(style.rotateAnimIn);
      }
    }
  };

  useEffect(() => {
    let box = document.querySelector('#highlightBox') as HTMLElement;
    if (box && boxDetails) {
      box.style.height = boxDetails.height;
      box.style.width = boxDetails.width;
      box.style.top = `calc(${boxDetails.top} - ${padding})`;
      box.style.left = `calc(${boxDetails.left} - ${padding})`;
    }
  }, [boxDetails]);

  useEffect(() => {
    let res = new ResizeObserver(calcBoxDetails);
    let sec = new IntersectionObserver(calcAnimations, { threshold: 1 });
    const highlight = document.querySelector('#highlight');
    highlight ? res.observe(highlight) : null;
    const highlightInner = document.querySelector('#highlightInner');
    highlightInner ? sec.observe(highlightInner) : null;

    setTimeout(() => setBoxDetails(getBoxDetails()), 50);
  }, []);

  return (
    <div
      id="highlight"
      className={style.highlight}
      style={{ backgroundImage: `url(${highlightImage?.fixed?.src})` }}
    >
      <div id="highlightBox" className={style.highlightBox}></div>
      <div className={style.highlightContent}>
        <div id="highlightInner" className={style.highlightInner}>
          <HighlightCard
            title={highlightTitle}
            subtitle={highlightSubtitle?.highlightSubtitle}
            description={highlightDescription?.highlightDescription}
            buttonText={highlightButton}
            buttonLink={highlightLink}
          ></HighlightCard>
        </div>
      </div>
    </div>
  );
}

export default Highlight;
