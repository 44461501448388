import React, { useState, useEffect } from 'react';
import style from './home.banner.module.scss';
import ResizeObserver from 'resize-observer-polyfill';
import { PrimaryAnchorButton } from '../buttons';
import { Definite, PropComponent } from '../../helpers/dataHelpers';

export type BannerProps = Pick<Definite<GatsbyTypes.HomeQuery['contentfulHomeContent']>, 'bannerTitle' | 'bannerSubtitle' | 'bannerButton' | 'bannerLink'>;

export const Banner: PropComponent<Partial<BannerProps>> = ({bannerTitle, bannerSubtitle, bannerButton, bannerLink}) => {
    const padding = '48px';

    let [boxDetails, setBoxDetails] = useState({
        height: `0px`,
        width: `0px`,
        top: `0px`,
        left: `0px`
    });

    let calcBoxDetails = () => {
        let content = document.querySelector('#bannerInner') as HTMLElement;
        if (content) {
            setBoxDetails(getBoxDetails());
        }
    };
    let getBoxDetails = () => {
        let content = document.querySelector('#bannerInner') as HTMLElement;
        return {
            height: `calc(${content.offsetHeight + content.offsetTop}px + 10vh)`,
            width: `${content.offsetWidth}px`,
            top: `${content.offsetTop}px`,
            left: `${content.offsetLeft}px`,
        };
    }
    let calcAnimations = (entries) => {
        for(let entry of entries) {
            let box = document.querySelector('#bannerBox');
            if (!box) continue;
            if(entry.isIntersecting) {
                box.classList.remove(style.rotateAnimOut);
                box.classList.add(style.rotateAnimIn);
            } else {
                box.classList.remove(style.rotateAnimIn);
                box.classList.add(style.rotateAnimOut);
            }
        }
    }

    useEffect(() => {
        let box = document.querySelector('#bannerBox') as HTMLElement;
        if (box && boxDetails) {
            box.style.height = boxDetails.height;
            box.style.width = boxDetails.width;
            // box.style.top = `calc(${boxDetails.top} - ${padding})`;
            box.style.left = `calc(${boxDetails.left} - ${padding})`;
        }
    }, [boxDetails]);

    useEffect(() => {
        let res = new ResizeObserver(calcBoxDetails);
        let sec = new IntersectionObserver(calcAnimations, {threshold: 1});
        const banner = document.querySelector('#banner');
        banner ? res.observe(banner) : null;
        const bannerInner = document.querySelector('#bannerInner');
        bannerInner ? sec.observe(bannerInner) : null;
        setTimeout(() => setBoxDetails(getBoxDetails()), 50)
    }, []);


    return (<div id="banner" className={style.banner}>
        <div id="bannerBox" className={style.bannerBox}></div>
        <div className={style.bannerContent}>
            <div id="bannerInner" className={style.bannerInner}>
                <h1>{bannerTitle}</h1>
                <div className={style.bannerSubheader}>{bannerSubtitle}</div>
                <div className={style.bannerSpacer}></div>
                <PrimaryAnchorButton href={`./${bannerLink}`}>{bannerButton}</PrimaryAnchorButton>
                <div className={style.bannerMobileSpacer}></div>
            </div>
        </div>
    </div>)
}

export default Banner;
