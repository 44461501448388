import React, { useEffect, useState } from 'react';
import { PropComponent } from '../helpers/dataHelpers';
import { FlatButton } from './buttons';
import { Icon } from './icon';
import Modal from './modal';
import Share from './share';
import style from './shareModal.module.scss';

export type ShareModalProps = {
  url: string;
  className: string;
};

const ShareModal: PropComponent<ShareModalProps> = ({ url, className }) => {
  const [showModal, setShowModal] = useState(false);

  const shareClicked = () => {
    // @ts-ignore
    if (navigator.share) {
      nativeShare();
    } else {
      setShowModal(true);
    }
  };

  const nativeShare = () => {
    navigator
      .share({
        title: 'Share',
        text: 'Check out this article',
        url: window.location.href,
      })
      .then(() => console.log('Successful share'))
      .catch(error => console.log('Error sharing', error));
  };
  return (
    <div className={className}>
      <FlatButton
        className={style.shareButton}
        onClick={shareClicked}
      >
        Share
        <Icon className={style.shareIcon}>share</Icon>
      </FlatButton>
      <Modal
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
      >
        <Share
          title={'Share'}
          url={url}
          closeShare={() => {
            setShowModal(false);
          }}
        ></Share>
      </Modal>
    </div>
  );
};

export default ShareModal;
