import React from 'react';
import { Definite, PropComponent } from '../../helpers/dataHelpers';
import SimpleCard from '../simpleCard';
import style from './home.news.module.scss';

export type NewsProps = {
    news: (Pick<GatsbyTypes.ContentfulArticle, "title" | "slug" | "createdAt"> & {
        description: GatsbyTypes.Maybe<Pick<GatsbyTypes.contentfulArticleDescriptionTextNode, "description">>;
    })[]
};

export const News: PropComponent<Partial<NewsProps>> = ({news = []}) =>  {
    return (<div className={style.news}>
        <div className={style.newsContent}>
            <h2>Centeva in the news</h2>
            <div className={style.articles}>
                {news.map(n => (<div key={n?.slug} className={style.article}>
                    <SimpleCard
                        title={n?.title}
                        createdAt={n?.createdAt}
                        description={n?.description?.description}
                        link={`/article/${n?.slug}`}
                    />
                </div>))}
            </div>
        </div>
    </div>);
}

export default News;