import React from 'react';
import { FacebookIcon, TwitterIcon } from './icon';
import style from './share.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from './icon';
import { data } from '../pages/team';
import { DataComponent, PropComponent } from '../helpers/dataHelpers';

export type ShareProps = {
  closeShare: () => void;
  title?: string;
  url?: string;
};

const Share: PropComponent<ShareProps> = ({ closeShare, title, url }) => {
  const data = useStaticQuery<GatsbyTypes.SiteInfoQuery>(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          baseUrl
        }
      }
    }
  `);
 
  return <ShareInner {...{ closeShare, title, url, data}} />;
    
};

export type ShareInnerProps = Parameters<typeof ShareInner>['0'];

export const ShareInner: DataComponent<GatsbyTypes.SiteInfoQuery, ShareProps> = ({ closeShare, title, url, data }) => {

  const copyUrlToClipboard = () => {
    let dummy = document.createElement('input'),
      text = `${data?.site?.siteMetadata?.baseUrl}/${url}`;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };
 

  return (
    <div className={style.shareContainer}>
      <div className={style.header}>
        <h3 className={style.title}>Share</h3>
        <button
          className={style.menuButton}
          data-testid="close-button"
          onClick={() => {
            closeShare();
          }}
        >
          <Icon className={style.closeIcon}>close</Icon>
        </button>
      </div>
      <a className={style.shareItem} target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${data?.site?.siteMetadata?.baseUrl}/${url}&display=popup&ref=plugin&src=like`}>
        <FacebookIcon fill={'#3b5998'} className={style.icon} />
        Facebook
      </a>
      <a className={style.shareItem} target="_blank" href={`https://twitter.com/intent/tweet?text=${title}&url=${data?.site?.siteMetadata?.baseUrl}/${url}&via=Centeva`}>
        <TwitterIcon fill={'#1da1f2'} className={style.icon} />
        Twitter
      </a>
      <a className={style.shareItem} target="_blank" href={`mailto:?subject=Check%20out%20this%20article!&body=${data?.site?.siteMetadata?.baseUrl}/${url}`}>
        <Icon  className={style.emailIcon} >email</Icon>
        Email
      </a>
      <div tabIndex={0} className={style.shareCopy} onClick={copyUrlToClipboard}>
        Click To Copy Link
        <div className={style.url}>{`${data?.site?.siteMetadata?.baseUrl}/${url}`}</div>
      </div>
    </div>
  );
}

export default Share;
