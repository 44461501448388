import React from 'react';
import style from './highlightCard.module.scss';
import { SecondaryAnchorButton } from './buttons';
import { PropComponent } from '../helpers/dataHelpers';

export type HighlightCardProps = {
    title: string,
    subtitle: string,
    description: string,
    buttonText: string,
    buttonLink: string
}

const HighlightCard: PropComponent<Partial<HighlightCardProps>> = ({ title, subtitle, description, buttonText, buttonLink }) => {
    return (<div className={style.card}>
        <h2 className={style.cardTitle}>{title}</h2>
        <div className={style.cardSubtitle}>{subtitle}</div>
        <p className={style.cardDescription}>{description}</p>
        <div className={style.cardButton}>
            <SecondaryAnchorButton href={buttonLink}>{buttonText}</SecondaryAnchorButton>
        </div>
    </div>);
}

export default HighlightCard;