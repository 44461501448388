import React from 'react';
import { PropComponent } from '../helpers/dataHelpers';
import style from './modal.module.scss';

export type ModalProps = {
  showModal: boolean,
  closeModal: () => void
}
const Modal: PropComponent<ModalProps> = ({showModal, closeModal, children}) => {
  return(
    <div data-testid="wrapper" className={showModal ? style.backDrop : style.hideDialog} onClick={() => {closeModal()}}>
      <div className={style.dialogOuter} onClick={(e) => {e.stopPropagation()}}>
        <section className={style.dialog}>
          {children}
        </section>
      </div>
    </div>
  )
};

export default Modal;
