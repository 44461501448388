import React from "react";
import { PropComponent } from "../helpers/dataHelpers";
import { TertiaryLinkButton } from "./buttons";
import ShareModal from "./shareModal";
import style from './simpleCard.module.scss';

export type SimpleCardProps = {
  title: string,
  description: string,
  link: string,
  createdAt: string,
  showShare: boolean
};

const SimpleCard: PropComponent<Partial<SimpleCardProps>> = ({title, description, link, createdAt, showShare}) => {
  return (<div className={style.card}>
    <h3>{title}</h3>
    <span>{createdAt}</span>
    <p>{description}</p>
    {link && <div className={style.actions}>
      {showShare ? <ShareModal className={style.shareButton} url={link}></ShareModal>: <div></div>}
      <TertiaryLinkButton to={link}>Read More</TertiaryLinkButton>
    </div>}
  </div>);
};

export default SimpleCard;
