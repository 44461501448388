import React from 'react';
import style from './home.contact.module.scss';
import { SecondaryAnchorButton } from '../buttons';
import { Icon } from '../icon';
import { Definite, PropComponent } from '../../helpers/dataHelpers';

type ContactProps = Pick<Definite<GatsbyTypes.HomeQuery['contentfulHomeContent']>, 'contactButton' | 'contactLink' | 'contactTitle'>;

export const Contact: PropComponent<Partial<ContactProps>> = ({contactButton, contactLink, contactTitle}) => {
    return (<div className={style.contact}>
        <div className={style.contactContent}>
            <h2>{contactTitle}</h2>
            <div>
                <SecondaryAnchorButton href={`${contactLink}`}>{contactButton} <Icon className={style.contactIcon}>mail</Icon> </SecondaryAnchorButton>
            </div>
        </div>
    </div>);
}

export default Contact;
