import React from 'react';
import style from './home.insight.module.scss';
import { SecondaryAnchorButton, TertiaryAnchorButton } from '../buttons';
import classNames from '../../helpers/classNames';
import { Icon } from '../icon';
import { Definite, PropComponent } from '../../helpers/dataHelpers';

export type InsightProps = Partial<Pick<
    Definite<GatsbyTypes.HomeQuery['contentfulHomeContent']>,
    'articles' |
    'primaryArticle'
>>;

const Insight: PropComponent<InsightProps> = ({articles = [], primaryArticle}) => {
    return (<div className={style.insight}>
        <div className={style.header}><h2>Insights & Trends</h2></div>
        <div className={style.cards}>
            <LargeInsightCard {...primaryArticle}></LargeInsightCard>
            <div className={style.smallCards}>
                {articles.map(a => a?.__typename === 'ContentfulTrend' ? <TrendCard {...a}  key={a.title}/> : a?.__typename === 'ContentfulInsight' ? <InsightCard {...a}  key={a.title}/> : <></>)}
            </div>
        </div>
        <div className={style.footer}>
            <SecondaryAnchorButton href="/article">See more articles <Icon className={style.articleIcon}>subject</Icon> </SecondaryAnchorButton>
        </div>
    </div>);
}

export default Insight;

type InsightCardProps = Pick<GatsbyTypes.ContentfulInsight, 'title' | 'slug'> &
    { image: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulAsset, "title"> & { readonly resize: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulResize, "src">>; }>} &
    { description: GatsbyTypes.Maybe<Pick<GatsbyTypes.contentfulInsightDescriptionTextNode, "description">>};

const InsightCard: PropComponent<InsightCardProps> = ({title, description, slug, image}) => {
    return (<div className={style.card}>
        <div className={style.cardImage}>
            {image && <img src={image?.resize?.src} title={image?.title}/>}
        </div>
        <div className={style.cardDetails}>
            <h3>{title}</h3>
            <p>{description?.description}</p>
            <TertiaryAnchorButton href={`/article/${slug}`} data-testid={slug}>Read Article</TertiaryAnchorButton>
        </div>
    </div>);
}

const TrendCard: PropComponent<Pick<GatsbyTypes.ContentfulTrend, "id" | "title" | "url" | "imageUrl">> = ({title, imageUrl, url}) => {
    return (<div className={style.card}>
        <div className={style.cardImage}>
            <img src={imageUrl} title={title}/>
        </div>
        <div className={style.cardDetails}>
            <h3>{title}</h3>
            <TertiaryAnchorButton target="_blank" href={url} data-testid={url}>Read Article</TertiaryAnchorButton>
        </div>

    </div>)
}

type LargeInsightCardProps = Partial<Pick<GatsbyTypes.ContentfulInsight, "slug" | "title"> & 
{ image: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulAsset, "title"> & { fixed: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulFixed, "src">>}>} &
{ description: GatsbyTypes.Maybe<Pick<GatsbyTypes.contentfulInsightDescriptionTextNode, "description">>}>;

const LargeInsightCard: PropComponent<LargeInsightCardProps> = ({title, description, slug, image}) => {
    return (<div className={classNames(style.card, style.primaryCard)}>
        <img src={image?.fixed?.src} title={image?.title}/>
        <div className={style.cardDetails}>
            <h3>{title}</h3>
            <p>{description?.description}</p>
            <TertiaryAnchorButton href={`/article/${slug}`} data-testid={slug}>Read Article</TertiaryAnchorButton>
        </div>
    </div>);
}
