import React from 'react';
import style from './index.module.scss';
import { graphql } from 'gatsby';

import BetterHeader from '../components/header/betterHeader';
import Banner from '../components/home/home.banner';
import Insight from '../components/home/home.insight';
import Expertise from '../components/home/home.expertise';
import Highlight from '../components/home/home.highlight';
import News from '../components/home/home.news';
import Testimonial from '../components/home/home.testimonial';
import Contact from '../components/home/home.contact';
import Footer from '../components/footer';
import Seo from '../components/seo';
import { DataComponent } from '../helpers/dataHelpers';

 const Home: DataComponent<GatsbyTypes.HomeQuery> = ({data}) => {
  return (<div className={style.home}>
      <Seo></Seo>
      <BetterHeader />
      <Banner {...data.contentfulHomeContent}></Banner>
      <Insight {...data.contentfulHomeContent}></Insight>
      <Expertise {...{ capabilities: data.allContentfulCapability, categories: data.allContentfulCapabilityCategory}}></Expertise>
      <Highlight {...data.contentfulHomeContent}></Highlight>
      <News {...{news: data.allContentfulArticle.edges.map(n => n.node)}}></News>
      <Testimonial {...data.contentfulHomeContent}></Testimonial>
      <Contact {...data.contentfulHomeContent}></Contact>
      <Footer></Footer>
  </div>)
}
export default Home;

export const dataQuery = graphql`
query Home {
  contentfulHomeContent(current: {eq: true}) {
    articles {
      ... on ContentfulInsight {
        id
        slug
        title
        category
        description {
          description
        }
        image {
          title
          resize(quality: 100) {
            src
          }
        }
      }
      ... on ContentfulTrend {
        id
        url
        title
        imageUrl
      }
    }
    bannerButton
    bannerImage {
      fixed {
        src
      }
      title
    }
    bannerLink
    bannerSubtitle
    bannerTitle
    highlightDescription {
      highlightDescription
    }
    highlightImage {
      fixed(width: 1920, height: 1080, quality: 100) {
        src
      }
      title
    }
    highlightLink
    highlightSubtitle {
      highlightSubtitle
    }
    highlightTitle
    testimonial {
      testimonial
    }
    testimonialImage {
      title
      fixed(quality: 100, width: 1920, height: 1080) {
        src
      }
    }
    highlightButton
    primaryArticle {
      image {
        fixed(quality: 100) {
          src
        }
        title
      }
      description {
        description
      }
      title
      slug
    }
    news {
      slug
      title
      date(formatString: "MMMM D, YYYY")
    }
    contactButton
    contactLink
    contactTitle
  }
  allContentfulCapability(sort: {fields: order}) {
    edges {
      node {
        name
        slug
        category {
          title
          id
          slug
        }
        icon {
          file {
            url
          }
        }
      }
    }
  }
  allContentfulArticle(filter: {category: {category: {eq: "News"}}}, limit: 3, sort: {fields: createdAt}) {
    edges {
      node {
        description {
          description
        }
        slug
        title
        createdAt(formatString: "MMMM D, YYYY")
      }
    }
  }
  allContentfulCapabilityCategory {
    edges {
      node {
        id
        title
      }
    }
  }
}

`;